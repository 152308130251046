<template>
  <a-drawer
    :destroy-on-close="true"
    :title="title"
    :visible="visible"
    :width="800"
    @close="closeDrawer"
  >
    <a-form-model
      ref="form"
      :label-col="{
        span: 4,
        style: {
          textAlign: 'left'
        }
      }"
      :model="form"
      :rules="rules"
      :wrapper-col="{
        span: 20
      }"
    >
      <a-divider v-if="containerizationType === 'kubernetes'" orientation="left" style="margin-top: 0">基本配置</a-divider>
      <a-form-model-item label="名称" prop="name">
        <a-input v-model="form.name" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item
        label="所属操作系统"
        prop="os"
      >
        <source-select
          v-model="form.os"
          :disabled="isUpdate"
          source-type="os"
        ></source-select>
      </a-form-model-item>
      <a-form-model-item prop="groups">
        <group-select v-model="form.groups" source-type="containerization"></group-select>
        <question-icon
          slot="label"
          title="所属群组"
          description="用于分组查询及告警配置"
        ></question-icon>
      </a-form-model-item>
      <template v-if="containerizationType === 'kubernetes'">
        <a-divider orientation="left">监控配置</a-divider>
        <a-form-model-item label="Token">
          <a-input v-model="form.macro.api_token" type="textarea" :rows="5"></a-input>
        </a-form-model-item>
        <a-form-model-item label="APIServer 地址">
          <a-input
            v-model="form.macro.api_url"
            :max-length="128"
            placeholder="默认为 https://localhost:6443"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="Controller 地址">
          <a-input
            v-model="form.macro.controller_url"
            :max-length="128"
            placeholder="默认为 https://localhost:10257"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="Kubelet 地址">
          <a-input
            v-model="form.macro.kubelet_url"
            :max-length="128"
            placeholder="默认为 https://localhost:10250"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="Scheduler 地址">
          <a-input
            v-model="form.macro.scheduler_url"
            :max-length="128"
            placeholder="默认为 https://localhost:10259"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item
          label="kube-state-metrics 地址"
          :label-col="{
            span: 24,
            style: {
              textAlign: 'left'
            }
          }"
          :wrapper-col="{
            span: 24
          }"
        >
          <a-input
            v-model="form.macro.state_url"
            :max-length="128"
            placeholder="默认为 http://kube-state-metrics"
          ></a-input>
        </a-form-model-item>
      </template>
      <a-form-model-item
        :wrapper-col="{ span: 20, offset: 4 }"
        style="margin-bottom: 0"
      >
        <a-space size="middle">
          <a-button :loading="loading" type="primary" @click="ok">
            确定
          </a-button>
          <a-button @click="closeDrawer">取消</a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {
  getContainerization,
  updateContainerization,
  createContainerization,
  getContainerizationMonitorMacro
} from '@/api/containerization'
import QuestionIcon from '@/components/icon/QuestionIcon'
import GroupSelect from '@/components/select/GroupSelect'
import SourceSelect from '@/components/select/SourceSelect'

export default {
  name: 'InputDrawer',
  components: {
    QuestionIcon,
    GroupSelect,
    SourceSelect
  },
  data () {
    return {
      visible: false,
      isUpdate: false,
      form: {
        id: '',
        name: '',
        groups: undefined,
        os: undefined,
        macro: {}
      },
      rules: {
        name: [
          {
            message: '请输入名称',
            required: true,
            trigger: 'blur'
          }
        ],
        os: [
          {
            message: '请选择所属操作系统',
            required: true,
            trigger: 'change'
          }
        ],
        groups: [
          {
            message: '请选择所属群组',
            required: true,
            trigger: 'select'
          }
        ]
      },
      loading: false,
      containerizationType: ''
    }
  },
  computed: {
    title () {
      let type = ''
      let update = ''
      if (this.containerizationType === 'docker') type = 'Docker '
      else type = 'Kubernetes '
      if (this.isUpdate) update = '编辑'
      else update = '创建'
      return type + update
    }
  },
  methods: {
    show (id, containerizationType) {
      this.containerizationType = containerizationType
      if (id) {
        const promiseArr = []
        this.isUpdate = true
        const p1 = getContainerization(id)
          .then(res => {
            const data = res.data
            this.form.id = data.id
            this.form.name = data.name
            this.form.groups = data.groups
            this.form.os = data.os
          })
          .catch(() => {})
        promiseArr.push(p1)
        if (this.containerizationType === 'kubernetes') {
          const p2 = getContainerizationMonitorMacro(id).then(res => {
            this.form.macro = res.data
          })
          promiseArr.push(p2)
        }
        Promise.all(promiseArr).then(() => {
          this.visible = true
        })
      } else {
        this.isUpdate = false
        this.visible = true
      }
    },
    closeDrawer () {
      this.form = {
        id: '',
        name: '',
        groups: undefined,
        os: undefined
      }
      if (this.containerizationType === 'kubernetes') this.form.macro = {}
      this.$refs.form.resetFields()
      this.visible = false
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const params = {
            name: this.form.name
          }
          if (this.containerizationType === 'kubernetes' && Object.keys(this.form.macro).length !== 0) {
            params.macro = this.form.macro
          }
          if (this.form.groups) params.group_ids = this.form.groups.map(group => group.id)
          if (this.isUpdate) {
            updateContainerization(this.form.id, params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.closeDrawer()
              })
              .finally(() => {
                this.loading = false
              })
          } else {
            params.os_id = this.form.os.id
            params.containerization_type = this.containerizationType
            createContainerization(params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.closeDrawer()
              })
              .finally(() => {
                this.loading = false
              })
          }
        }
      })
    }
  }
}
</script>

<style></style>
